import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import {PageHeader, PageSection, TriageList, PageIntro, PrimaryCallOut, FilterProvider, AnchorLinks } from '../../components';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Efficacy Data for KEYTRUDA® (pembrolizumab) | Clinical Trial Results | HCP`,
    keywords: `keytruda efficacy data, pembrolizumab efficacy data, keytruda clinical trial results`,
    description: `Health care professionals can review efficacy data for KEYTRUDA® (pembrolizumab) and clinical trial results in FDA-approved indications.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/","@type":"Substance","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"Browse the indications approved for treatment with KEYTRUDA® (pembrolizumab). Open to review efficacy and safety information, and you will also find links to related information like dosing, mechanism of action, and more. If you're using the filter, you'll only see the indications you have selected. Reset your filter to see all the indications.","activeIngredient":"pembrolizumab","recognizingAuthority":"Merck"}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/","@type":"MedicalWebPage","name":"Select a Cancer Type","description":"Browse the indications approved for treatment with KEYTRUDA® (pembrolizumab). Open to review efficacy and safety information, and you will also find links to related information like dosing, mechanism of action, and more. If you're using the filter, you'll only see the indications you have selected. Reset your filter to see all the indications."}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const Index = ({ location }) => {
    
    return (
        <FilterProvider>
            <DefaultLayout jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
                <div data-template-name="template-d">
                    {/* Begin Intro Column */}
                    <TemplateColumn id="template-b-intro" maxWidth={824}>
                        <PageHeader title="Efficacy"/>
                        <PageSection title="Select a Cancer Type" bgColor="cloud" centerTitle={true}>
                            <PageIntro centered={true}>
                                <p>Browse the indications approved for treatment with KEYTRUDA® (pembrolizumab). Open to review efficacy and safety information, and you will also find links to related information like dosing, mechanism of action, and more. If you're using the filter, you'll only see the indications you have selected. Reset your filter to see all the indications.</p>
                            </PageIntro>
                        </PageSection>
                    </TemplateColumn>
                    {/* End Intro Column */}
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-b-main">
                        <PageSection>                           
                            <TriageList listType='efficacy'/>
                        </PageSection>
                        <PageSection bgColor="cloud">
                            <PrimaryCallOut
                                title="Not seeing the type of tumor you are looking for?"
                                content="For more information, including a complete list of indications KEYTRUDA is approved to treat, please see the full Prescribing Information for KEYTRUDA."
                                secondaryAction={{ label: 'View Prescribing Information', href: '/prescribing-information/', external: true, target: '_blank' }}
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}
                </div>
            </DefaultLayout>
        </FilterProvider>
    );
};

export default Index;